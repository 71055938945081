
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:wght@400;700&display=swap');


:root{
	--lfc-red: #DC0714;
	--lfc-yellow: #FFD700;
	--lfc-white: #FFF;
	--lfc-grey: #384750;
	--lfc-light-grey: #FAFBFB;

	--body-gutter: 1.3rem; /* 20.5px */
	--body-gutter-alt: 1.125rem; /* 18x */
	--lfc-font-alt: 'Roboto', sans-serif;
	--lfc-font-main: 'Roboto Condensed', sans-serif;
	--lfc-body-text-size: 1rem;
	--lfc-body-line-height: 1.5rem;
	
	--input-padding: 1rem;
	--input-font-size: 1rem;
	--input-label-font-size: calc(var(--input-font-size) * 0.75);
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: 'Roboto Condensed', sans-serif;
	font-family:var(--lfc-font-main);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--lfc-grey);
	background: var(--lfc-white);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

a{
	text-decoration: none;
	color: var(--lfc-grey);
}

a.red{
	text-decoration: underline;
	font-style: italic;
	color: var(--lfc-red);
}

button{
	outline:none !important;
}

@media (min-width: 768px){
  :root{
    --body-gutter-alt: 2rem; /* 32px */
    --body-border:.5rem; /* 8px */
  }
}

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #21759b;
	display: block;
	font-size: 0.875rem;
	font-weight: 700;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

.borderedPage {
	border-left:var(--body-border) var(--lfc-red) solid;
    border-right:var(--body-border) var(--lfc-red) solid;
    border-bottom:var(--body-border) var(--lfc-red) solid;
  
    padding: var(--body-gutter);
    font-family: var(--lfc-font-alt);
    font-size: var(--lfc-body-text-size);
    line-height: var(--lfc-body-line-height);
}

@keyframes throb{
    0%{
        filter:brightness(.9);
    }
    50%{
        filter:brightness(1);
    }
    100%{
        filter:brightness(.9);
    }
}