.checkbox{
    padding:1rem 0;
    position: relative;
    cursor: pointer;
}

.checkbox__input{
    position: absolute;
    left: -99999px;
}

.checkbox__virtual{
    position: absolute;
    top:calc(50% - 1rem);
    right:0;
    cursor: pointer;
    width:2.6rem;
    height: 2rem;
}

.checkbox__virtual::after{
    content:"";
    position: absolute;
    right: .35rem;
    top:.3rem;
    width:2.14rem;
    height: 1.6rem;
    background: transparent;
    border-radius: 9px;
    transition: all 300ms;
}
.checkbox__input:checked ~ .checkbox__virtual::after{
    background: var(--lfc-red);
}
.checkbox__virtual::before{
    content:"";
    position: absolute;
    right: 0;
    top:0;
    width:100%;
    height:100%;
    border:2px solid var(--lfc-red);
    border-radius: 12px;
}

