.headerNav__button{
    background:none;
    border:none;
    outline: none !important;
    display: inline;
    color: var(--lfc-white);
    display:flex;
    padding: 1rem 0;
    cursor: pointer;
}

.headerNav__arrow{
    margin-left:1rem;
    width:1rem;
    height:1rem;
    transform: rotate(180deg);
    transition: all 250ms ease-in-out;
}

.isOpen .headerNav__arrow{
    transform: rotate(360deg);
}


.headerNav__button:hover .headerNav__arrow{ 
    animation: arrowWobble 600ms ease-in-out 2;
}