.Heading{
    font-size: 24px;
    line-height: 28px;
    line-height: 2;
    font-family: var(--lfc-font-main);
    font-weight: normal;
}

.Heading__red{
    color: var(--lfc-red);
}

.Heading__black{
    color: var(--lfc-grey);
}

.Heading__white{
    color:var(--lfc-white);
}

.Heading__bold{
    font-weight: bold;
}
.Heading__uppercase{
    text-transform: uppercase;
}