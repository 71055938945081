.RecentSearch{
    border-top: 2px solid transparent;
    height:100%;
    width:100%;
    display:block;
    background:white;
    max-height: 0;
    transition: all 500ms ease-out;
    overflow: hidden;
}

.RecentSearch.isOpen{
    border-color: var(--lfc-red);
    max-height: 40vh;
}

.RecentSearch__title{
    margin:0;
    padding: 1rem 1.125rem;
    letter-spacing: 1.14px;
    color: var(--lfc-grey);
    font-weight: bold;
    font-size:1rem;
    display: inline-block;
    line-height:20px;
}

.RecentSearch__list{
    list-style:none;
    padding-left:1.125rem;
    margin-top:0;
}
.RecentSearch__list li a{
    display: block;
    padding:0 0 1rem 0;
    letter-spacing: 1.14px;
    color:var(--lfc-red);
    line-height:20px;
}