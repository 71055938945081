.footer{
    --logos-imgpadding: 2rem;
    --logos-padding: 0;
    --logos-size: 64px;
    --logos-maxwidth: 140px;
    --footer-letter-spacing: 1px;
    --footer-imgmargin: 0 2rem;

    letter-spacing: var(--footer-letter-spacing);
    font-size: 16px;
    line-height: 2;
}
@media (max-width:798px){
    .footer{
        --logos-padding: 1.2rem;
        --footer-imgmargin: .3rem;
    }
}

.footer{
    background: var(--lfc-red);
    padding-top: 3rem;
    color: white;
}

.footer .accordion{
/* .footer a{ */
    color: white;
    margin: 0 5vw;
    text-decoration: none;
}
.footer .accordion a {
    color: white;
    text-decoration: none;
}
.footer .accordion .accordion__title > * {
    font-size: 1rem;
    letter-spacing: 1px;
}
.footer .accordion .accordion__content {
    font-size: 0.85rem;
}
.footer .accordion .accordion__title-arrow{
    display: none;
    top:3px;
}
.footer .accordion .accordion__title-arrow path{
    fill: #fff;
}

.footerlogos{
    margin: 2rem 0;
    text-align: center;
    padding: var(--logos-padding);
}

.footerlogos__whitebg {
    background:var(--lfc-white);
}
@media (max-width: 798px){
    .footerlogos{
        display:flex;
        align-items: center;
        flex-wrap:wrap;
        max-width: 200px;
        justify-content: center;
        margin: 0 auto;
    }
}
.footerlogos a img{
    max-height: var(--logos-size);
    padding: var(--logos-imgpadding);
    margin: var(--footer-imgmargin);
    height: var(--logos-size);
    max-width: var(--logos-maxwidth);
}
.hr{
    border-top: 1px solid white;
    margin: 10px 0;
    opacity: 0.5;
    width:100%;
}

.colophon{
    text-align: center;
    padding: 1rem 0;
    font-size: 12px
}

.footer p{
    margin: 0;
}