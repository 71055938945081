.LoaderButton__block{
    width:100%;
    display:block;
}
.LoaderButton__text{
    display: block;
}
.LoaderButton__text.loading{
    display: none;
}

.LoaderButton__loader {
    height: 8px;
    width: 100%;
    text-align: center;
}