.button {
    background: var(--lfc-red);
    border: 4px solid var(--lfc-red);
    color: var(--lfc-white);
    font-style: italic;
    font-weight: bold;
    display: inline-block;
    letter-spacing: 1px;
    padding: 1rem 3.5rem;
    cursor: pointer;
    font-size: var(--lfc-body-font-size);
    font-family: var(--lfc-font-main);
    text-align: center;
    filter: saturate(1);
    transition: all 250ms;
}

.button:disabled{
    filter: saturate(0);
}