
@media (min-width: 900px){
    .thankyou{
        border-left: var(--body-border) var(--lfc-red) solid;
        border-right: var(--body-border) var(--lfc-red) solid;
        border-bottom: var(--body-border) var(--lfc-red) solid;
    }

    .thankyou__content{
        padding: var(--body-gutter);
        font-family: var(--lfc-font-alt);
        font-size: var(--lfc-body-text-size);
        line-height: var(--lfc-body-line-height);
    }
    
}


.thankyou__content{
    padding: var(--body-gutter);
    font-family: var(--lfc-font-alt);
    font-size: var(--lfc-body-text-size);
    line-height: var(--lfc-body-line-height);
}