.PaymentPage{
  border-left:var(--body-border) var(--lfc-red) solid;
  border-right:var(--body-border) var(--lfc-red) solid;
  border-bottom:var(--body-border) var(--lfc-red) solid;

  padding: var(--body-gutter);
  font-family: var(--lfc-font-alt);
  font-size: var(--lfc-body-text-size);
  line-height: var(--lfc-body-line-height);
}

@media all and (min-width: 598px) {
    .PaymentPage {
      /* padding-right: 50vw; */
      display:flex;
    }

    .PaymentPage > div,
    .PaymentPage > form{
      width:100%;
    }
  
    .ResetPassword .success {
      max-width: 400px;
    }
}
