.ResetPassword{

}

@media all and (min-width: 480px) {
    .ResetPassword {
      padding-right: 50vw;
    }
  
    .ResetPassword .success {
      max-width: 400px;
    }
}
  
.ResetPassword .success {
  margin: 0 auto;
  text-align: center;
}
.ResetPassword .success .glyphicon {
  color: grey;
  font-size: 30px;
  margin-bottom: 30px;
}

.ResetPassword__back{
  text-align: center;
  width:100%;
  display: block;
}