.Signup{
  font-family: var(--lfc-font-alt);
  font-size: var(--lfc-body-text-size);
  line-height: var(--lfc-body-line-height);
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.Signup h1
{
  margin: 2.5rem 0;
  letter-spacing: 4px;
}
.Signup h2{
  letter-spacing: 4px;
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}
.Signup h3{
  letter-spacing: 0px;
  font-size: var(--body-font-size);
}
.Signup .Select{
  width:100%;
  margin: 1rem 0;
}
.pwstrength{
  padding: 10px;
  background: #d6dadc;
}

.pwstrength .invalid {
  padding-left: 24px;
}

.pwstrength .valid::before {
  content: '';
  display: inline-block;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  background: url(../components/images/tick.svg);
  background-size: 16px 16px;
}

.passwords-do-not-match {
  margin: -8px 0 0 16px;
  color: var(--lfc-red);
  font-size: 14px;
}

.passwords-do-not-match.hidden {
  display: none;
}

@media all and (min-width: 598px) {
  .Signup form {
    max-width: 50%;
  }
}
