.BillingMeta,
.BillingMeta h1,
.BillingMeta h3,
.BillingMeta p,
.BillingMeta span {
    font-family: Arial, Helvetica, sans-serif;
}

@media all and (min-width: 600px) {
    .BillingMeta h2 {
        padding-left: 30%;
    }
}

.BillingMeta__content {
    padding: 0 0% 0 0%;
}

@media all and (min-width: 600px) {
    .BillingMeta__content{
        padding: 0 10% 0 30%;
    }
}

.BillingForm .card-field {
    padding: var(--input-padding) 16px;
    border-radius: 6px;
    border: 1px solid #CCC;
    box-shadow: 0px 3px 6px #0000000F;
    border: 1px solid #3847501F;
    line-height: 1.3333333;
    font-family: Arial, Helvetica, sans-serif;
}

.BillingForm__row {
    display: flex;
}

.BillingForm__row>* {
    flex: 1 1 auto;
    width: 100%;
}

.BillingForm__stripegroup .card-number-field {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
}

.BillingForm__stripegroup .card-number-field {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.BillingForm .cvc-field {
    border-radius: 0;
    border-left: 0;
    border-bottom-right-radius: 4px;
}

.BillingForm .expiry-field {
    border-radius: 0;
    border-bottom-left-radius: 4px;
}

.BillingForm .labeledInput {
    margin-top: 3rem;
    box-shadow: 0px 3px 6px #0000000F;
}

.button.PromoCode__apply {
    margin-top: 1rem;
}

.BillingForm .PromoCode {
    margin-bottom: 4rem;
}

.BillingForm .PromoCode .labeledInput {
    margin-top: 2rem;
}

.BillingForm .button {
    font-style: normal;
    font-weight: normal;
}

.BillingForm .labeledInput__label {
    font-family: Arial, Helvetica, sans-serif;
    top: -1.4rem !important;
    left: 0;
    background: 0;
    font-weight: bold;
    font-size: .75rem;
}

.BillingForm .labeledInput__input {
    border: 1px solid #3847501F;
}

.BillingForm__label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: .75rem;
}

.BillingForm .card-field.StripeElement--focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 5px #dc0714a8;
    border-color: var(--lfc-red);
}

.the-price {
    font-weight: 600;
    font-size: 40px;
    padding-right: 10px;
}

.the-price.discounted {
    color: var(--lfc-red);
}

.the-original-price {
    align-self: flex-end;
    font-size: 18px;
    opacity: 0.7;
    padding-right: 10px;
    color: var(--lfc-grey);
    text-decoration: line-through;
}

.per-year {
    font-size: 14px;
}

.pay-with-card {
    font-weight: 600;
    padding: 10px 0px;
}

.BillingForm__submit {
    border-radius: 6px;
}

.Billing__subscription {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 15%;
}

.Billing__subscription-option {
    width: 20rem;
    height: 20rem;
}

.Billing__subscription-option-true {
    border-width: 3em;
    border-radius: 3em;
    border: solid #DC0714;
    box-shadow: 0 0 0.05em 0.05em #DC0714;
    cursor: pointer;
}

.Billing__subscription-select {
    opacity: 50%;
    width: 100%;
    fill: inherit;
}

.Billing__subscription-option-false {
    border-width: 3em;
    border-radius: 3em;
    border: solid #ffffff;
    box-shadow: 0 0 0.05em 0.05em #ffffff;
    cursor: pointer;
}