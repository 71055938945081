.categoryPage__title{
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 2.375rem;
}

.categoryPage{
    margin: 0 var(--body-gutter-alt)
}

@media (min-width:798px){
    .categoryPage__videos{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 2.125rem;
    }
}
