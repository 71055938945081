.headerNav__fold{
    max-height: 0;
    background: var(--lfc-red);
    padding: 0 1.5rem 0 1.5rem;
    overflow: hidden;
    transition: padding 400ms ease-out, max-height 250ms ease-in-out;
}

.headerNav__fold ul{
    padding:0;
    margin:0;
    list-style: none;
}
.headerNav__fold ul li a{
    display: block;
    text-decoration: none;
    color:var(--lfc-white);
    text-transform: uppercase;
    font: normal normal bold 12px/16px Roboto Condensed;
    letter-spacing: 1px;
    padding: .5rem 0 1rem 0;
}
.headerNav__fold.isOpen {
    max-height: 10rem;
    padding: 0 1.5rem .5625rem 1.5rem;
    animation: dropAndBounce .6s linear 1;
}
.headerNav__fold.hidden.isOpen,
.headerNav__fold.hidden{
    max-height: 0;
    padding:0 0;
}

@keyframes dropAndBounce{
    0%{
        padding: 0 1.5rem 0rem 1.5rem;
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
    }
    25%{
        padding: 0 1.5rem 1rem 1.5rem;
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
    }
    50%{
        padding: 0 1.5rem .4rem 1.5rem;
        border-bottom-left-radius: 5%;
        border-bottom-right-radius: 5%;
    }
    100%{
        padding: 0 1.5rem .5625rem 1.5rem;
    }
}