.thumbnailCTA{
    height: 320px;
    background: var(--lfc-grey);
    position: relative;
    padding:2.5rem 4rem;
    background-size:cover;
    background-position: bottom;
}

.thumbnailCTA::after{
    content:"";
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #0000003E 100%) 0% 0% no-repeat padding-box;
    opacity: 0.64;
    z-index: 1;
}

.thumbnailCTA__title{
    position: relative;
    z-index: 2;
    font-size: 1.75rem;
    max-width: 90%;
    line-height: 2rem;
    font-weight: normal;
}

.thumbnailCTA__duration{
    position: relative;
    z-index: 2;
    color:var(--lfc-white);
    font-size:1rem;
    letter-spacing: 1px;
}

.thumbnailCTA__button{
    position: relative;
    z-index: 2;
    border:4px solid var(--lfc-white);
    padding: 1.1875rem 2.25rem  1.1rem 2.25rem;
    color: var(--lfc-white);
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
    font-style: italic;
    font-size:1rem;
    position: absolute;
    bottom:2.5rem;
    left:4rem;
    background:transparent;
    transition: all 250ms ease-in-out;
}

.thumbnailCTA__button:hover,
.thumbnailCTA__button:focus{
    background: var(--lfc-white);
    color: var(--lfc-grey);
}
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin: auto;
  }

.video-container-fullwidth{
     position: absolute;
     object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


@media (max-width:798px){
    .thumbnailCTA{
        padding:2.1875rem var(--body-gutter);
        height: 480px;
    }

    .thumbnailCTA__button{
        margin: 0 var(--body-gutter);
        display: block;
        bottom:1.875rem;
        left:0;
        right:0;
    }
}