.LoadingSpinner {
    position: relative;
    display: block;
    font-size: 0;
    color: currentColor;
    height: 8px;
    width: 24px;
    text-align: center;
}

.LoadingSpinner > div {
    width: 4px;
    height: 4px;
    margin: 2px;
    border-radius: 100%;
    animation: loaderButtonPulse 1s ease infinite;
    display: inline-block;
    background-color: currentColor;
    border: 0;
}

.LoadingSpinner > div:nth-child(1) {
    animation-delay: -200ms;
}
.LoadingSpinner > div:nth-child(2) {
    animation-delay: -100ms;
}

@keyframes loaderButtonPulse {
    0%,
    50%,
    100% {
        opacity: 1;
        transform: scale(1);
    }
    25% {
        opacity: 0.1;
        transform: scale(0.01);
    }
}