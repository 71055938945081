.PromoCode{
    display:flex;
    align-items: center;
}

.PromoCode > *{
    width:100%;
    flex:1 1 auto;
}

.PromoCode .labeledInput{
    margin-left:0;
}

.PromoCode__apply{
    width:50%;
    margin: 0 0 0 10px;
    border-radius: 6px;
    padding: calc(1rem - 3px) 2rem;
    font-size: 1rem;
    line-height:normal;
}