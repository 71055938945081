.Select{
    border:1px solid #38475040;
    border-radius: 4px;
    display:block;
    padding:var(--input-padding);
    font-size: var(--input-font-size);
    background: url(../images/greyarrow.svg) no-repeat;
    background-position: right center;
    -webkit-appearance: none; 
-moz-appearance: none;
appearance: none;
background-color: white;
}