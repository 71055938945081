.SearchPage{
    padding:0 var(--body-gutter);
}

.SearchPage__videos{
    margin:2rem 0;
    min-height: 200px;
}

@media (min-width:798px){
    .SearchPage__videos{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2.125rem;
    }
}