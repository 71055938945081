.ChangePassword{
    border-left:var(--body-border) var(--lfc-red) solid;
    border-right:var(--body-border) var(--lfc-red) solid;
    border-bottom:var(--body-border) var(--lfc-red) solid;
  
    padding: var(--body-gutter);
    font-family: var(--lfc-font-alt);
    font-size: var(--lfc-body-text-size);
    line-height: var(--lfc-body-line-height);
}
.ChangePassword h1{
    letter-spacing: 3.36px;
    padding-bottom: 1.5rem;
}
.ChangePassword .button{
    margin-bottom:2rem;
}