
.labeledInput {
    position:relative;
    margin:1rem auto;
    background: white;
}
.labeledInput:focus .labeledInput__label{
    top:0;
}

.labeledInput__input{
    border:1px solid #38475040;
    border-radius: 4px;
    position:relative;
    background:none;
    display:block;
    padding:var(--input-padding);
    width: calc( 100% - 2rem);
    font-size: var(--input-font-size);
    font-family: var(--lfc-font-alt);
    color:transparent;
    transition: all 300ms;
    min-height: 22px;
   
}

.labeledInput__input.active,
.labeledInput__input:focus{
    color: currentColor;
}

.labeledInput__input:focus + label {
    top:-0.8rem;
}
.labeledInput__input:focus:invalid + label{
    color: var(--lfc-red);
}
.labeledInput__input:focus{
    outline: none;
}

.labeledInput__input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    color:var(--lfc-grey) !important;
}

.labeledInput__label {
    position:absolute;
    transition: top 500ms;
    letter-spacing: 1px;
    top: var(--input-padding);
    left: var(--input-padding);
    pointer-events:none;
    background:white;
    font-size: var(--input-label-font-size);
}

.labeledInput__label.active {
    top: -0.8rem;
}