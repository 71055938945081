@keyframes fadeToTopIn{
    from{
        opacity: 0;
    }
    to{
        opacity:1;
    }
}

.ToTop{
    position: fixed;
    bottom:3rem;
    right:-3.38rem;
    transform: rotate(-90deg);
    width:10rem;
    background: #67140A;
    display: flex;
    z-index: 2;
    animation: fadeToTopIn 200ms 1;
}

.ToTop__button{
    background:none;
    border:none;
    outline:none;
    color:white;
    font-size:20px;
    padding: 1.331875rem 1.78125rem 0.5rem 1.78125rem;
    letter-spacing: 1px;
    font-weight: bold;
    font-style: italic;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate3d( 0, 0, 0);
    cursor: pointer;
}
.ToTop__arrow{
    position: relative;
}
.ToTop__arrow span{
    display: block;
    background:white;
    height:2px;
    width:.6rem;
    position: absolute;
    transform:rotate(45deg);
    top:calc(50% - .225rem);
    left:0;
}
.ToTop__arrow span:last-child{
    transform:rotate(-45deg);
    top:calc(50% + .225rem);
}