.blockCTA {
    display: block;
    background: var(--lfc-yellow);
    color: var(--lfc-red);
    padding: 2vh 10vw;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 2rem var(--body-gutter);
}

.blockCTA_content {
    font-size: 1.125rem;
}

.blockCTA_content a {
    color: var(--lfc-red);
    margin: 2rem auto;
    text-decoration: none;
    position: relative;
}

.blockCTA_content a::before {
    content: "";
    position: absolute;
    width: 0px;
    height: 1px;
    bottom: 2px;
    left: 4px;
    background-color: var(--lfc-red);

    transition: width 400ms;
    transform-origin: left;
    animation-fill-mode: backwards;
}

.blockCTA_content a:hover::before {
    width: 33px;
}

.blockCTA__period-wrapper {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;

}

.blockCTA__period {
    text-align: center;

}

.blockCTA .title {
    margin: 2rem auto;
    color: var(--lfc-red);
    font-size: 28px;
}

.blockCTA__ .blockCTA a {
    display: block;
    color: var(--lfc-red);
    margin: 2rem auto;
    font-size: 28px;
    text-decoration: none;

}

.blockCTA ul {
    list-style: none;
    padding: 0;
}

.blockCTA ul li:before {
    content: "-";
    margin-left: -1rem;
    margin-right: 0.5rem;
}

.blockCTA__access {
    margin-bottom: 0;
    font-size: 1.125rem;
    letter-spacing: 2.52px;

}


.blockCTA__price {
    font-size: 2em;
    font-weight: bold;
    margin-top: 1rem;
    letter-spacing: 0.15em;
}

.blockCTA .divider {
    align-self: center;
    height: 50%;
    background: var(--lfc-red);
}
