.NavBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
    background:white;
    transition: all 500ms;
  }

  .NavBar.active{
      background: var(--lfc-red);
  }

  .NavBar__search path{
    fill: var(--lfc-red);
  }

  .NavBar__left{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    max-width: 20vw;
    grid-gap: 16px;
  }
  .NavBar.active .NavBar__left{
    position: relative;
  }
  .NavBar__lfc{
    transition: all 500ms;
    position: relative;
    left:0;
  }
  .NavBar.active .NavBar__lfc{
      width: 90vw;
      text-align: center;
  }
  .NavBar.active .NavBar__left svg path{
      fill: var(--lfc-white);
  }
  .NavBar.active .NavBar__right{
      display: none;
  }
  .NavBar.active .navigation{
      position: absolute;
      top:-200vh;
  }
  .NavBar__right{
    justify-self: end;
  }

  .NavBar__back{
      position: absolute;
      left:1.5rem;
      color:white;
      font-size:2rem;
      z-index: 2;
  }