.masthead {
  background: var(--lfc-red);
  height: 10vh;
  max-height: 10vh;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  color: var(--lfc-white);
  justify-content: space-between;
  transition: max-height 500ms;
}

.masthead.hidden{
  max-height: 0;
  overflow: hidden;
}

.masthead__right {
  justify-self: end;
  display: flex;
  align-items: flex-start;
  max-width: 40vw;
}
.masthead__right > *{
  margin:0 0 0 1rem;
}

.masthead__right > a {
  align-self: center;
}

.menu{
  min-width: 20px;
}

.icon{
  max-height: 25px;
  height: 25px;
}

.sc{
  max-height: 44px;
  height: 44px;
}

.arrow{
  height: 8px;
  margin-left: 8px;
}
