@keyframes toastIn{
    from{
        transform:scaleY(0);
        transform-origin:top;
    }
    to{
        transform:scaleY(1);
    }
}

.borderedPage + .toasts__wrapper{
    border-left: var(--body-border) var(--lfc-red) solid;
    border-right: var(--body-border) var(--lfc-red) solid;
    
}

.toastsProviderWrapper{
    display:flex;
    flex-direction: column-reverse;
}

.toast {
    --toast-height: 4rem;
    position: relative;
    width:100%;
    height:var(--toast-height);
    z-index: 99;
    background:var(--lfc-white);
    animation: toastIn 800ms ease-in-out 1;
    overflow:hidden;
}

.toast__text {
    color:var(--lfc-grey);
    padding: 1.25rem 1.125rem;
    font-size: 1.125rem;
    line-height: 32px;
    letter-spacing: 0.38px;
}

.toast__closeBtn {
    position: absolute;
    right:1rem;
    top: 1rem;
    background: none;
    border: none;
    height: 10px;
    width: 10px;
    padding:1rem;
}

.toast__closeBtn span{
    position: relative;
    display: block;
    background: #384750;
    opacity: 0.3;
    width:10px;
    height:2px;
    transform:rotate(45deg)
}
.toast__closeBtn span:nth-child(2){
    top:2px;
    transform:rotate(-45deg)
}