.LoadingClay{
    display:inline-block;
    animation: throb 1.75s infinite;
    background:#adb4b8;
    border-radius: 4px;
    vertical-align: middle;
}

.LoadingClay.red {
    background: var(--lfc-red);
}

.LoadingClay.white {
    background: var(--lfc-white);
}