.NavigationSearch{
    position: relative;
    display: flex;
    align-items: center;
}
.NavigationSearch > form{
    display:flex;
}
.NavigationSearch__input{
    width:0px;
    border:0;
    outline: none !important;
    margin:0 .5rem 0 0;
    transition: width 350ms ease;
    padding-left: .5rem;
    line-height: 2em;
}
.isOpen .NavigationSearch__input{
    width: 190px;
    border-radius: 2px;
}
.NavigationSearch__input::placeholder{
    font-size:1rem;
    letter-spacing: 1.14px;
    color:var(--lfc-grey);
    opacity:.5;
    font-family: var(--lfc-font-main);
}

.NavigationSearch__button{
    background:none;
    outline:none !important;
    border:0;
    cursor: pointer;
}
@media (max-width:320px){
    .isOpen .NavigationSearch__input{
        padding-left: 10px;
        background: white;
        margin-left: -100px;
    }
}

@media (min-width:321px) and (max-width:410px){
    .isOpen .NavigationSearch__input{
        padding-left: 50px;
        background: white;
        margin-left: -50px;
    }
}

