.accordion{
    text-align: left;
}
.accordion__header{
    display: block;
    cursor: pointer;
    position: relative;
    background:none;
    border:0;
    outline:none!important;
    padding:0 0;
}

.accordion__title{
    display: inline-block;
}

.accordion__title-arrow{
    display: inline-block;
    position: relative;
    right: -1.5rem;
    margin-bottom: 0.25rem;
    width: .75rem;
    transform: rotate(180deg);
    transition: transform 200ms;
}
@media (max-width:798px){
    .accordion__title-arrow{
        position: absolute;
        top:calc(50% - .25rem);
        right:-1.5rem;
    }
    .accordion__header{
        max-width: 90%;
        margin-left:0;
        margin-right: auto;
    }
}

.accordion__title-arrow.active{
    transform:rotate(0deg);
   
}
.accordion__title-arrow path{
    fill: var(--lfc-grey);
    transition: fill 300ms;
}
.accordion__title-arrow.active path{
    fill: var(--lfc-red);
}
.accordion__content{
    overflow: hidden;
    transition: height 200ms;
}