.hScrollArea{
    position: relative;
}

.hScrollArea__area{
    scroll-behavior: smooth;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
    -webkit-overflow-scrolling: touch;
}

.hScrollArea__button{
    opacity:0;
    position: absolute;
    top:10px;
    height: 260px;
    width:5rem;
    transition: all 300ms ease;
    border:0;
    z-index: 2;
    outline:0 !important;
}

@media (hover: hover){
    .hScrollArea:hover .hScrollArea__button:not(.none){
        opacity:1;
        cursor: pointer;
    }
}
@media (hover: none), (max-width:798px) {
    .hScrollArea:hover .hScrollArea__button{
        display: none;
    }
}

@keyframes scrollButtonBounceLeft{
    0%{
        transform: translate(0) rotate(90deg);
    }
    50%{
        transform: translate(-.5rem) rotate(90deg);
    }
    100%{
        transform: translate(0) rotate(90deg);
    }
}

@keyframes scrollButtonBounceRight{
    0%{
        transform: translate(0) rotate(-90deg);
    }
    50%{
        transform: translate(.5rem) rotate(-90deg);
    }
    100%{
        transform: translate(0) rotate(-90deg);
    }
}
.hScrollArea__button.none{
    pointer-events: none;
}

.hScrollArea__button-next{
    right:0;
    background: linear-gradient(to left, rgba(0,0,0,0.4), transparent)
}
.hScrollArea__button-prev{
    left:0;
    background: linear-gradient(to right, rgba(0,0,0,0.4), transparent);
    /* clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 50%, 75% 25%, 75% 75%, 25% 50%, 25% 100%, 100% 100%, 100% 0%); */
}
.hScrollArea__button-prev::after,
.hScrollArea__button-next::after
{
    height: 50px;
    width: 50px;
    content:"";
    position: absolute;
    top: calc(50% - 25px);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3e%3cpath style='fill:white' d='M225.813 48.907L128 146.72 30.187 48.907 0 79.093l128 128 128-128z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
}
.hScrollArea__button-prev::after{
    transform:rotate(90deg);
    left:.5rem;
}
.hScrollArea__button-next::after{
    transform:rotate(-90deg);
    right:.5rem;
}

.hScrollArea__button-next.animate:after {
    animation: scrollButtonBounceRight 800ms alternate;
}
.hScrollArea__button-prev.animate:after {
    animation: scrollButtonBounceLeft 800ms alternate;
}


.hScrollArea .hScrollArea__area::-webkit-scrollbar {
    display: none;
}
