.Login form a {
    margin-bottom: 15px;
    font-size: 14px;
}

.Login__forgot-password-wrapper {
    text-align: right;
    padding: 0 0 1rem 0;
}

.Login__forgot-password {
    color: var(--lfc-red);
    text-decoration: underline;
    margin: 1rem 0;
    font-style: italic;
}

.Login__price-wrapper {
    display: flex;
    flex-direction: row;
}

.Login__price {
    width: 15em;
}

.Login__title,
.SignUp__title {
    letter-spacing: 4px;
}

.Login .forgot-password {
    text-align: end;
    text-decoration: underline;
    font-style: italic;
}

.SignUp {
    margin-top: calc(var(--body-gutter) * 2);
}

.SignUp__price-desc {
    text-align: left;
    letter-spacing: 2.52px;
    margin-bottom: .25rem;
}

.SignUp__price {
    display: block;
    letter-spacing: 0.5px;
    color: var(--lfc-grey);
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 2rem 0;
    font-weight: bold;
}

@media all and (min-width: 798px) {
    .Login {
        padding-bottom: calc(var(--body-gutter) * 2) 0;
    }

    .Login form {
        max-width: 50%;
    }
}

@media all and (max-width: 798px) {

    .Login__button,
    .SignUp__button {
        display: block;

    }

    .Login__button {
        width: 100%;
    }
}