.quote{
    text-align: center;
    padding: 1rem 0;
    margin:0 var(--body-gutter);
    color: var(--lfc-red);
    line-height: 32px;
}
@media screen and (min-width: 600px) {
    .quote{
        max-width: 600px;
        margin: 0 auto;
    }
}
.quote p{
    font-size: 28px;
}

.quote img{
    margin: 1rem 0;
    max-width: 140px;
}

.quote footer{
    font-size:24px;
}