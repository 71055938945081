.videoslider{
    margin: 3rem 0;
}

.videoslider__false-videos .LoadingClay,
.videoslider .videocard{
    margin: 10px 0 10px var(--body-gutter-alt);
}

h3.slidertitle{
    margin: 10px var(--body-gutter-alt);
    text-transform: uppercase;
    letter-spacing: 4px;
}

.videoslider__false-videos {
    display: flex;
    overflow: hidden;
}

.videoslider__false-videos .loadingVideo{
    border-radius: 12px;
}

.videoslider__false-videos .LoadingClay{
    margin-top:6px;
    margin-bottom: 2px;
}