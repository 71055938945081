
.LabeledSelect{
    position:relative;
    margin:1rem auto;
}

.LabeledSelect__select{
    position:relative;
    width: 100%;
    background: url(../images/greyarrow.svg) no-repeat;
background-position: right center;
-webkit-appearance: none; 
-moz-appearance: none;
appearance: none;
background-color: white;
}

.LabeledSelect__select:focus + label {
    top:-0.8rem;
}
.LabeledSelect__select:focus{
    outline: none;
}

.LabeledSelect__label{
    background:white;
    position:absolute;
    transition: top 500ms;
    letter-spacing: 1px;
    top: -0.8rem;
    left: var(--input-padding);
    pointer-events:none;
    background:white;
    font-size: var(--input-label-font-size);
}