.MyAccount{
    border-top:1px solid var(--lfc-red);
  
    padding: var(--body-gutter);
    font-family: var(--lfc-font-alt);
    font-size: var(--lfc-body-text-size);
    line-height: var(--lfc-body-line-height);
  }

  .SignOutButton{
    background:none;
    border:none;
    color: var(--lfc-red);
    font-style:italic;
    text-decoration: underline;
    font-size: 1rem;
    cursor: pointer;
  }
  .MyAccount h1{
    letter-spacing: 3.36px;
    padding-bottom: 1rem;
  }
  .MyAccount h2{
      font-size:1rem;
  }
  .MyAccount a.red{
      display: block;
      margin-bottom: 1.5rem;
  }
  .MyAccount__unpaid-button{
    margin: 1rem 1rem 1rem 0rem;
    display: inline-block;
  }

@media (max-width: 796px){
  .MyAccount__unpaid-button{
    display:block;
  }
}