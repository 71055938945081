
@media (min-width: 900px){
    .videoPage{
        border-left: var(--body-border) var(--lfc-red) solid;
        border-right: var(--body-border) var(--lfc-red) solid;
        border-bottom: var(--body-border) var(--lfc-red) solid;
    }

    .videoPage__content{
        padding: var(--body-gutter);
        font-family: var(--lfc-font-alt);
        font-size: var(--lfc-body-text-size);
        line-height: var(--lfc-body-line-height);
    }
    
}

.videoPage .shaka-controls-button-panel{
    background:#00000026;
}

.material-icons{
    font-family: "Material Icons" !important;
}

.videoMeta, .videoDescription{
    margin: 0 var(--body-gutter-alt)
}
.videoMeta{
    margin-bottom: 2rem;
}
.videoMeta > *{
    margin-right:2rem;
}
.videoMeta .Heading{
    margin-bottom:.75rem;
}
.videoMeta .duration{
    display:inline-block;
    font-size: 12px;
    font-weight: bold;
}

.videoDescription{
    letter-spacing: 1px;
    font-size: 14px;
    line-height:20px;
    font-family: var(--lfc-font-alt);
    margin-bottom: 2rem;
}
.videoDescription__material{
    letter-spacing: 1px;
    margin-top:2rem;
}
.videoDescription__disclaimer{
    color:var(--lfc-grey);
    opacity:.75;
    letter-spacing: 1px;
    margin-top:2rem;
}

.video-container{
    width: 640px;
    margin: auto;
    max-width: 100%;
}

.shaka-video{
    width: 100%;
    height: 100%;
}

.videoPage__unpaid {
    position: relative;
    text-align: center;
    overflow: hidden;
}

.videoPage__unpaid-cta{
    background: rgba(1,1,1,0.8);
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    z-index: 5;
}
.videoPage__unpaid-cta > p{
    color:var(--lfc-white);
}
.videoPage__unloaded-Img,
.videoPage__unpaid > img{
    width: 100%;
    min-height: 200px;
}

.videoPage__unloaded-Img{
    background: #adb4b8;
    animation: throb 1.75s infinite;
}

