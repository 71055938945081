.categoryBubble{
    padding: 10px;
    border-radius: 12px;
    letter-spacing: 1px;
    font-size: 12px;
    line-height:16px;
    background: #3847501A;
    display:inline-block;
    text-transform: uppercase;
    color: var(--lfc-grey);
}