.navigation__inner{
  height: 100vh;
  overflow: hidden;
  background: var(--lfc-red);
  color: white;
  position: fixed;
  top: -120vh;
  left:0;
  width: 100vw;
  z-index: 10;
  flex-direction: column;
  transition: all 400ms ease-in-out;
}

.isOpen .navigation__inner{
  top:0;
}

.navigation__scrollwrap{
  position: relative;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.navigation a {
  color: var(--lfc-white);
}

.navigation__toggle{
  background:none;
  border:none;
  width:2rem;
  height:2rem;
}

.navlist{
  display: block;
  flex-direction: column;
  justify-content: space-between;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 1px;
  border-bottom: 1px solid white;
  padding: 0 5vw 1.5rem 5vw;
  margin-top:4rem;
}
.navlist li{
  list-style: none;
}
.navlist li a{
  text-decoration: none;
  color:inherit;
  width:100%;
  display:block;
}

.navlist li a:after{
  content: ">";
  float: right;
}

.navlist li a.active:after{
  content: "";
}

.navlist li a.active{
  font-weight: 700;
  text-decoration: underline;
}
.navaccount{
  width: calc(100% - 10vw);
  padding: 0 5vw;
}

.navigation__social{
  width: calc(100% - 10vw);
  padding: 0 5vw;
  text-align: center;
}

.navigation__social-holder{
  display: flex;
  margin-top: 20px;
  justify-content: center;
  width: 75%;
  margin: 2rem auto;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.4px;
  align-items: flex-end;
}
.navigation__social-holder > a{
  margin: 0 1rem;
}

.navigation__social-title{
  letter-spacing: 0px;
  font-size: 2.5rem;
  line-height:2;
  margin-bottom:0;
}

.navigation__payment{
  padding:1.125rem 0;
  background: white;
  color: var(--lfc-grey);
  width: 100%;
  text-align: center;
  flex-grow: 1;
}

.navigation__payment-text{
  margin:0 0 1rem 0;
  font-size:.9rem;
  letter-spacing: 1px;
}
.navigation__payment-holder{
  display:flex;
  justify-content: space-between;
  width:50%;
  max-width: 400px;
  margin:0 auto;
}
.navigation__payment-holder img{
  height: auto;
  width: 30%;
  object-fit: contain;
}

.navigation__close{
  cursor: pointer;
  background:none;
  border:none;
  outline:none !important;
  width:2rem;
  height:2rem;
  text-align: right;
  position:absolute;
  top:1rem;
  right:4.2vw;
  padding:6px 0;  
}

.navigation__close svg path{
  fill:transparent;
  transition:fill 250ms ease-in-out;
}

.isOpen .navigation__close svg path{
  fill:white;
}