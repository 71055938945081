.videocard{
    text-decoration: none;
    display: block;
    user-select: none;
    -webkit-user-drag: none;
}
.videocard.wide{
    margin: 2rem 0;
}

.videocard.wide .thumbnail{
    width:100%;
    height:350px;
    user-select: none;
    pointer-events: none;
}

.videocard .thumbnail{
    height: 260px;
    width: 208px;
    background: grey;
    border-radius: 12px;
    position: relative;
    background-size: cover !important;
}

.videocard .category{
    padding: 10px;
    border-radius: 12px;
    font-weight: 700;
    letter-spacing: 1.4px;
    font-size: 12px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: white;
    color:black;
}

.videocard .title{
    font-weight: 300;
    letter-spacing: 1.14px;
    margin: 6px 0;
    color: var(--lfc-grey);
}

.videocard .videodescription{
    font-size: 12px;
    font-weight: 700;
}
.videocard .duration,
.videocard .difficulty,
.videocard .premium{
    margin-right: 20px;
}

.videocard .duration{
    color:#38475080;
}

.videocard .premium {
    color: var(--lfc-red)
}
